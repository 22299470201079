import LegacyLoaderColors from "kt_jsgem/lib/config/legacy_loader_colors";
import KTService from "kt_jsgem/lib/kt_service";

import userFromInitialSettings from "kt_jsgem/lib/config/user_from_initial_settings";

class LegacyLoader {
  constructor(legacyConfig) {
    this.legacyConfig = legacyConfig;
    this.language = undefined;
  }

  load(language = this.defaultLanguage()) {
    const service = new KTService({});
    return service.legacyConfig({ user: userFromInitialSettings(this.legacyConfig.user), configPath: this.paths(language).legacyConfigPath }).
      then((categories) =>
        this.parseConfiguration(language, categories)
      );
  }

  parseSidepanel() {
    const sidepanel = {
      removeAnnotations: this.legacyConfig.sidepanel.removeAnnotations,
      languageLevelControl: this.legacyConfig.sidepanel.languageLevelControl,
      defaultLanguageLevel: this.legacyConfig.sidepanel.defaultLanguageLevel,
      lastCheck: this.legacyConfig.sidepanel.lastCheck,
      languageLevelView: this.legacyConfig.sidepanel.languageLevelView,
      addSuggestions: this.legacyConfig.sidepanel.addSuggestions,
      checkTypes: {
        correctness: this.legacyConfig.sidepanel.deepChecks && this.legacyConfig.sidepanel.deepChecks.correctness,
        readability: this.legacyConfig.sidepanel.deepChecks && this.legacyConfig.sidepanel.deepChecks.readability,
        correctnessFrisian: this.legacyConfig.sidepanel.deepChecks && this.legacyConfig.sidepanel.deepChecks.correctnessFrisian
      },
      language: {
        show: this.legacyConfig.sidepanel.language && this.legacyConfig.sidepanel.language.show,
        selected: this.language != null ? this.language : (this.legacyConfig.sidepanel.language && this.legacyConfig.sidepanel.language.selected),
        languages: {}
      },
      annotationInfo: this.legacyConfig.sidepanel.annotationInfo,
      copyText: this.legacyConfig.sidepanel.copyText,
      performCheckButton: this.legacyConfig.sidepanel.performCheckButton,
      groupedAnnotationTypes: this.legacyConfig.sidepanel.groupedAnnotationTypes,
      elearningButton: this.legacyConfig.sidepanel.elearningButton,
      fromLadButton: this.legacyConfig.sidepanel.fromLadButton,
      annotationCategorySelection: this.legacyConfig.sidepanel.annotationCategorySelection
    };

    if (this.legacyConfig.sidepanel.language && this.legacyConfig.sidepanel.language.services) {
      for (const key in this.legacyConfig.sidepanel.language.services) {
        const service = this.legacyConfig.sidepanel.language.services[key];
        sidepanel.language.languages[key] = {
          display: service.display
        };
      }
    }

    return sidepanel;
  }

  paths(language) {
    let addSuggestionURL, configURL, legacyConfigURL, webserviceURL, fetchFromLADURL;
    if (language && this.legacyConfig.sidepanel.language && this.legacyConfig.sidepanel.language.services && this.legacyConfig.sidepanel.language.services[language]) {
      const service = this.legacyConfig.sidepanel.language.services[language];
      webserviceURL = service.webserviceURL;
      addSuggestionURL = service.addSuggestionURL;
      legacyConfigURL = service.legacyConfigURL || service.webserviceURL;
      configURL = service.configurationURL;
    } else {
      webserviceURL = this.legacyConfig.webserviceURL;
      addSuggestionURL = this.legacyConfig.addSuggestionURL;
      legacyConfigURL = this.legacyConfig.legacyConfigURL || this.legacyConfig.webserviceURL;
      configURL = this.legacyConfig.configurationURL;
      fetchFromLADURL = this.legacyConfig.fetchFromLADURL;
    }

    return {
      analysePath: webserviceURL,
      addSuggestionPath: addSuggestionURL,
      legacyConfigPath: legacyConfigURL,
      configPath: configURL,
      fetchFromLADPath: fetchFromLADURL
    };
  }

  parseConfiguration(language, categories) {
    let annotationTypes = language && this.legacyConfig.sidepanel.language && this.legacyConfig.sidepanel.language.services && this.legacyConfig.sidepanel.language.services[language] ?
      this.legacyConfig.sidepanel.language.services[language].markCategories
    :
      this.legacyConfig.markCategories;

    annotationTypes = Array.from(annotationTypes).map((annotationType, i) => {
      const color = LegacyLoaderColors[annotationType.class_name];

      return {
        checkType: annotationType.deep_check === "1" ? "readability" : "correctness",
        id: annotationType.name,
        addableWordCategory: true,
        removableWordCategory: true,
        display: {
          name: annotationType.name,
          description: (categories.find((c) => c.name === annotationType.name_plural) || {}).description,
          markType: color[0],
          color: color[1],
          displayOrder: i + 1
        }
      };
    });

    const user = this.legacyConfig.user ? userFromInitialSettings(this.legacyConfig.user) : null;

    return {
      translations: this.legacyConfig.translations,
      sidepanel: this.parseSidepanel(),
      contextMenu: {
        ignoreButtons: this.legacyConfig.contextMenu && this.legacyConfig.contextMenu.ignoreButtons,
        showDescription: this.legacyConfig.contextMenu && this.legacyConfig.contextMenu.showDescription,
        addToPersonalList: this.legacyConfig.contextMenu && this.legacyConfig.contextMenu.addToPersonalList
      },
      webserviceURL: this.paths(language).analysePath,
      addSuggestionURL: this.paths(language).addSuggestionPath,
      user,
      annotationTypes,
      version: this.legacyConfig.version
    };
  }

  defaultLanguage() {
    if (((this.legacyConfig.sidepanel && this.legacyConfig.sidepanel.language) || {}).show && this.legacyConfig.sidepanel.language.selected) {
      return this.legacyConfig.sidepanel.language.selected;
    } else {
      return null;
    }
  }
}

export default LegacyLoader;
