import Buffer from "kt_jsgem/lib/buffer";
import ParagraphBoundry from "kt_jsgem/lib/paragraph_boundry";
import Range from "kt_jsgem/lib/range";

class FragmentList {
  constructor(textNodes) {
    this.buffer = new Buffer();
    this.fragments = this.initializeFromNodes(textNodes);
  }

  initializeFromNodes(textNodes) {
    this.fragments = [];
    for (const fragment of textNodes) {
      if (fragment instanceof ParagraphBoundry) {
        this.buffer.addParagraphBoundry();
      } else {
        this.buffer.addFragment(fragment);
        this.fragments.push(fragment);
      }
    }
    return this.fragments;
  }

  applyAnnotations(annotations, annotationElementCreator) {
    if(window.requestIdleCallback) {
      return this.applyAnnotationWithIdleCallback(annotations, annotationElementCreator);
    }else{
      return new Promise((resolve) => {
        for (const annotation of annotations) {
          this.splitForAnnotation(annotation);
        }

        for (const annotation of annotations) {
          this.wrapForAnnotation(annotation, annotationElementCreator);
        }
        resolve();
      });
    }
  }

  applyAnnotationWithIdleCallback(annotations, annotationElementCreator) {
    return new Promise((resolve) => {
      let areWrapping = false;
      let i = 0;
      const performStep = () => {
        if(!areWrapping) {
          this.splitForAnnotation(annotations[i]);
        }else {
          this.wrapForAnnotation(annotations[i], annotationElementCreator);
        }
        i++;

        if(!areWrapping && annotations.length === i) {
          areWrapping = true;
          i = 0;
        }
      };

      const idleCallback = (deadline) => {
        while(deadline.timeRemaining() > 0 && (!areWrapping || annotations.length !== i)) {
          performStep();
        }

        if(!areWrapping || annotations.length !== i) {
          window.requestIdleCallback(idleCallback, { timeout: 100 });
        }else{
          resolve();
        }
      };

      if(annotations.length > 0) {
        window.requestIdleCallback(idleCallback, { timeout: 100 });
      }else{
        resolve();
      }
    });
  }

  splitForAnnotation(annotation) {
    let newFragments = [];
    for (const fragment of this.fragments) {
      if (fragment.isEmpty()) { continue; }

      const intersectionRange = fragment.range.intersection(new Range(annotation));
      if(intersectionRange == null || intersectionRange.length === fragment.range.length) { continue; }

      const frags = fragment.split(intersectionRange.start - fragment.range.start, intersectionRange.length);
      newFragments = newFragments.concat(frags);
    }

    this.fragments = this.fragments.concat(newFragments);
  }

  wrapForAnnotation(annotation, annotationElementCreator) {
    for (const fragment of this.fragments) {
      if (fragment.isEmpty()) { continue; }

      if (new Range(annotation).contains(fragment.range)) {
        fragment.wrap(annotation, annotationElementCreator);
      }
    }
  }

  bufferAsString() {
    return this.buffer.toString();
  }
}

export default FragmentList;
