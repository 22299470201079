import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

class AddSuggestionsButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      suggestionsForm: new SuggestionsOverlayWrapper
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({ visible: false });
  }

  componentDidUpdate() {
    const { visible } = this.state;
    const {
      annotationTypes,
      newSuggestionSubmitted,
      translations
    } = this.props;

    this.state.suggestionsForm.render({
      translations: translations,
      onCloseClick: () => {
        this.setState({ visible: true });
      },
      onSubmitForm: newSuggestionSubmitted,
      visible: !visible,
      annotationTypes: annotationTypes
    });
  }

  render() {
    const { visible } = this.state;
    const { translations } = this.props;

    const attrs = {
      className: "button-element show-suggstions-form -white",
      onClick: this.handleClick,
      style: {
        display: visible ? "block" : "none"
      }
    };

    return (
      <div className="new-pompous-word">
        <button {...attrs}>
          {translations.addSuggestion || "Suggesties voor dure woorden geven"}
        </button>
      </div>
    );
  }
}

AddSuggestionsButtonComponent.propTypes = {
  annotationTypes: PropTypes.array.isRequired,
  newSuggestionSubmitted: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    addSuggestion: PropTypes.string.isRequired
  }).isRequired
};

class AlternativeInputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleAlternativeChange = this.handleAlternativeChange.bind(this);
  }
  handleRemoveClick(event) {
    event.preventDefault();
    this.props.onRemoveClick(this.props.id);
  }

  handleAlternativeChange(event) {
    this.props.onAlternativeChange(this.props.id, event.target.value);
  }

  render() {
    const {
      alternative,
      translations
    } = this.props;

    return (
      <div className="alternative-block">
        <table className="table-field">
          <tbody className="table-body">
            <tr className="table-row">
              <td className="table-field">
                <input
                  type="text"
                  name="alternatives[]"
                  placeholder={translations.addAlternativePlaceholder}
                  value={alternative}
                  onChange={this.handleAlternativeChange}
                />
              </td>
              <td className="table-field remove-alternative">
                <i className="fa fa-minus-circle" onClick={this.handleRemoveClick} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

AlternativeInputComponent.propTypes = {
  alternative: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onAlternativeChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    addAlternativePlaceholder: PropTypes.string.isRequired
  }).isRequired
};

class SuggestionAlternativesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleAddClick(event) {
    event.preventDefault();
    this.props.onAlternativeAdd();
  }

  render() {
    const {
      alternatives,
      translations,
      onAlternativeChange,
      onAlternativeRemove
    } = this.props;

    const alternativeComponents = [];
    for (const id in alternatives) {
      const alternative = alternatives[id];
      alternativeComponents.push(
        <AlternativeInputComponent
          key={id}
          id={id}
          alternative={alternative}
          onRemoveClick={onAlternativeRemove}
          onAlternativeChange={onAlternativeChange}
          translations={translations}
        />
      );
    }

    return (
      <div className="alternatives">
        <label className="alternatives-label">
          <div className="title">
            {translations.alternatives}
            {" "}
            <span className="optional">
              {translations.optional}
            </span>
            <i className="fa fa-plus-circle add-alternative" onClick={this.handleAddClick} />
          </div>
          {alternativeComponents}
        </label>
      </div>
    );
  }
}

SuggestionAlternativesComponent.propTypes = {
  alternatives: PropTypes.object.isRequired,
  onAlternativeAdd: PropTypes.func.isRequired,
  onAlternativeChange: PropTypes.func.isRequired,
  onAlternativeRemove: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    alternatives: PropTypes.string.isRequired,
    optional: PropTypes.string.isRequired
  }).isRequired
};

const addedToPersonalListTranslation = function(msg, word) {
  const parts = msg.split(":word");
  return parts.join(word);
};

class SuggestionMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleCloseClick(event) {
    event.preventDefault();
    this.props.onCloseClick();
  }

  render() {
    const {
      success,
      translations,
      word
    } = this.props;

    let message;
    if (success) {
      message = addedToPersonalListTranslation(translations.suggestionAdded, word);
    } else {
      message = addedToPersonalListTranslation(translations.suggestionNotAdded, word);
    }

    return (
      <div className="added-suggestion">
        <p>{message}</p>
        <button className="button-element blue exit" onClick={this.handleCloseClick}>
          {translations.close}
        </button>
      </div>
    );
  }
}

SuggestionMessageComponent.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  success: PropTypes.bool,
  translations: PropTypes.shape({
    close: PropTypes.string.isRequired,
    suggestionAdded: PropTypes.string.isRequired,
    suggestionNotAdded: PropTypes.string.isRequired
  }).isRequired,
  word: PropTypes.string.isRequired
};

class SuggestionsFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alternatives: {},
      count: 0,
      showError: false,
      name: "",
      annotationType: null
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAnnotationTypeChange = this.handleAnnotationTypeChange.bind(this);
    this.handleAlternativeAdd = this.handleAlternativeAdd.bind(this);
    this.handleAlternativeChange = this.handleAlternativeChange.bind(this);
    this.handleAlternativeRemove = this.handleAlternativeRemove.bind(this);
    this.handleRemoveFormClick = this.handleRemoveFormClick.bind(this);
    this.handleSubmitSuggestionClick = this.handleSubmitSuggestionClick.bind(this);
  }

  annotationTypes() {
    return Array.from(this.props.annotationTypes).filter((annotationType) => annotationType.addableWordCategory);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleAnnotationTypeChange(event) {
    this.setState({ annotationType: event.target.value });
  }

  handleAlternativeAdd() {
    const alternatives = Object.assign({}, this.state.alternatives);
    alternatives[this.state.count] = "";
    this.setState({
      alternatives: alternatives,
      count: this.state.count + 1
    });
  }

  handleAlternativeChange(id, alternative) {
    const alternatives = Object.assign({}, this.state.alternatives);
    alternatives[id] = alternative;
    this.setState({ alternatives: alternatives });
  }

  handleAlternativeRemove(id) {
    const alternatives = Object.assign({}, this.state.alternatives);
    delete alternatives[id];
    this.setState({ alternatives: alternatives });
  }

  handleRemoveFormClick(event) {
    event.preventDefault();
    this.props.onRemoveFormClick();
  }

  handleSubmitSuggestionClick(event) {
    event.preventDefault();

    // Validation if we have a name
    if ((this.state.name.length > 0) && (this.state.annotationType != null)) {
      const name = this.state.name;
      const alternatives = [];
      for (const id in this.state.alternatives) {
        const alternative = this.state.alternatives[id];
        if (alternative.length > 0) {
          alternatives.push(alternative);
        }
      }
      const annotationType = this.state.annotationType;

      this.props.onSubmitSuggestionClick({ word: name, alternatives: alternatives, annotationTypeId: annotationType });
    } else {
      this.setState({ showError: true });
    }
  }

  render() {
    const { translations } = this.props;

    return (
      <div>
        <p className="add-suggestion-description">
          {translations.addSuggestionExplanation}
        </p>
        <form action="#" method="post">
          <div>
            <label className="word-label" htmlFor="name">{translations.pompousWord}</label>
            <input
              type="text"
              name="name"
              placeholder={translations.addSuggestionPlaceholder}
              required={true}
              value={this.state.name}
              onChange={this.handleNameChange}
            />
            <div className="suggestion-types">
              <p>{translations.addSuggestionAnnotationTypes}</p>
              {this.annotationTypes().map((annotationType) =>
                <label className="suggestion-type-label" key={annotationType.id}>
                  <input
                    type="radio"
                    name="annotationType"
                    value={annotationType.id}
                    required={true}
                    onChange={this.handleAnnotationTypeChange}
                  />
                  {annotationType.display.name}
                </label>
              )}
            </div>
            <div className="error" style={{ display: this.state.showError ? "block" : "none" }}>
              {translations.suggestionEmpty}
            </div>
          </div>
          <SuggestionAlternativesComponent
            translations={translations}
            alternatives={this.state.alternatives}
            onAlternativeAdd={this.handleAlternativeAdd}
            onAlternativeChange={this.handleAlternativeChange}
            onAlternativeRemove={this.handleAlternativeRemove}
          />
          <div className="button-group">
            <button className="button-element" type="submit" onClick={this.handleSubmitSuggestionClick}>
              {translations.send}
            </button>
            <button className="button-element remove-suggestions-form" onClick={this.handleRemoveFormClick}>
              {translations.close}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SuggestionsFormComponent.propTypes = {
  annotationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      addableWordCategory: PropTypes.bool.isRequired,
      display: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onRemoveFormClick: PropTypes.func.isRequired,
  onSubmitSuggestionClick: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    addSuggestionAnnotationTypes: PropTypes.string.isRequired,
    addSuggestionExplanation: PropTypes.string.isRequired,
    addSuggestionPlaceholder: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
    pompousWord: PropTypes.string.isRequired,
    send: PropTypes.string.isRequired,
    suggestionEmpty: PropTypes.string.isRequired
  }).isRequired
};

class SuggestionsOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleSubmitSuggestionClick = this.handleSubmitSuggestionClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  getInitialState() {
    return {
      show: "form",
      suggestionSuccess: false
    };
  }

  translations() {
    return Object.assign({}, {
      addSuggestionExplanation: "Kent u een duur woord of dure woordgroep die Klinkende Taal nog niet markeert? " +
        "Stuur uw suggestie op naar de redactie. U kunt ook eenvoudigere alternatieven voor het woord meesturen.",
      pompousWord: "Duur woord",
      addSuggestionPlaceholder: "Vul hier uw dure woord of woordgroep in",
      suggestionEmpty: "U heeft het formulier nog niet volledig ingevuld.",
      addSuggestionAnnotationTypes: "Dit woord aanstrepen als:",
      alternatives:  "Alternatieven",
      optional: "(optioneel)",
      send: "Versturen",
      close: "Sluiten",
      suggestionAdded: "Bedankt voor uw suggestie. Zodra de redactie uw woord goedkeurt, " +
        "zal Klinkende Taal het in uw teksten en die van uw collega's markeren.",
      suggestionNotAdded: "Uw suggestie kon niet toegevoegd worden. Probeer het later nog een keer.",
      addAlternativePlaceholder: "Vul hier een eenvoudiger alternatief in"
    }
    , this.props.translations);
  }

  handleSubmitSuggestionClick(data) {
    this.props.onSubmitForm(data).then((success) => {
      this.setState({
        word: data.word,
        show: "alert",
        suggestionSuccess: success
      });
    });
  }

  handleCloseClick() {
    this.setState(this.getInitialState());
    this.props.onCloseClick();
  }

  render() {
    const { visible } = this.props;

    const element = this.state.show === "form" ?
      <SuggestionsFormComponent
        translations={this.translations()}
        onRemoveFormClick={this.handleCloseClick}
        onSubmitSuggestionClick={this.handleSubmitSuggestionClick}
        annotationTypes={this.props.annotationTypes}
      />
    : // alert
      <SuggestionMessageComponent
        translations={this.translations()}
        word={this.state.word}
        success={this.state.suggestionSuccess}
        onCloseClick={this.handleCloseClick}
      />;

    return (
      <div className="add-new-pompous-word" style={{ display: visible ? "block" : "none" }}>
        {element}
      </div>
    );
  }
}

SuggestionsOverlay.propTypes = {
  annotationTypes: PropTypes.array.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired
};

class SuggestionsOverlayWrapper {
  element() {
    if (!this.el) {
      this.el = document.createElement("div");
      document.body.appendChild(this.el);
    }
    return this.el;
  }

  render(props) {
    ReactDOM.render(<SuggestionsOverlay {...props} />,
      this.element());
  }
}

export default AddSuggestionsButtonComponent;
