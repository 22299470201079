import React from "react";
import PropTypes from "prop-types";

class CopyTextButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.onCopyText();
  }

  render() {
    return (
      <div className="copy-text">
        <input
          type="button"
          value={this.props.translations.copyText || "Tekst kopiëren"}
          readOnly={false}
          onClick={this.handleClick}
          className="button-element -white" />
      </div>
    );
  }
}

CopyTextButtonComponent.defaultProps = {
  translations: {}
};

CopyTextButtonComponent.propTypes = {
  onCopyText: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    copyText: PropTypes.string
  })
};

export default CopyTextButtonComponent;
