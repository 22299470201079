import React from "react";
import PropTypes from "prop-types";

class PerformCheckButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.onPerformCheckClicked();
  }

  render() {
    return (
      <div className="perform-check">
        <input
          className={`button-element ${this.props.disabled ? "-disabled" : ""}`}
          type="submit"
          onClick={this.handleClick}
          disabled={this.props.disabled}
          value={this.props.translations.evaluate} />
      </div>
    );
  }
}

PerformCheckButton.propTypes = {
  disabled: PropTypes.bool,
  onPerformCheckClicked: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    evaluate: PropTypes.string.isRequired
  }).isRequired
};

export default PerformCheckButton;
