// Range class
// Represents a range of characters
// Attributes:
// - start: first character in this range
// - end: last character in this range
// - length: the number of characters in this range
class Range {
  constructor(attrs) {
    this.start = attrs.start;
    if (attrs.end) {
      this.setEnd(attrs.end);
    } else if (attrs.length) {
      this.length = attrs.length;
      this.end = (this.start + this.length) - 1;
    }
  }

  equals(other) {
    return (this.start === other.start) && (this.end === other.end);
  }

  contains(other) {
    if (other instanceof Range) {
      return (other.start >= this.start) && (other.end <= this.end);
    } else {
      return (this.start <= other) && (other <= this.end);
    }
  }

  setEnd(end) {
    this.end = end;
    this.length = (1 + this.end) - this.start;
  }

  intersects(otherRange) {
    return otherRange.contains(this) ||
      this.contains(otherRange) ||
      otherRange.contains(this.start) ||
      otherRange.contains(this.end);
  }

  intersection(otherRange) {
    if (otherRange.contains(this)) {
      return new Range({ start: this.start, length: this.length });
    }
    if (this.contains(otherRange)) {
      return new Range({ start: otherRange.start, length: otherRange.length });
    }
    if (otherRange.contains(this.start)) {
      return new Range({ start: this.start, length: otherRange.end - this.start + 1 });
    }
    if (otherRange.contains(this.end)) {
      return new Range({ start: otherRange.start, length: this.end - otherRange.start + 1 });
    }
    return null;
  }
}

export default Range;
