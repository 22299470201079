import React from "react";

import PropTypes from "prop-types";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import { AnnotationTypeButtonComponent } from "./annotation_types_panel";

class AnnotationTypesGroupedPanelComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areAnnotationsOpenForCategory: {},
      areAnnotationsActiveForCategory: {}
    };

    this.toggleAnnotationCategory = this.toggleAnnotationCategory.bind(this);
  }

  stateForAnnotationType(annotationTypeId) {
    return this.props.annotationTypesState.find((ats) => ats.id === annotationTypeId);
  }

  toggleAnnotationCategory(annotationCategory) {
    this.setState((state) => {
      const current = state.areAnnotationsOpenForCategory[annotationCategory];
      if(current) {
        state.areAnnotationsOpenForCategory[annotationCategory] = false;
      }else{
        state.areAnnotationsOpenForCategory[annotationCategory] = true;
      }
      return state;
    });
  }

  toggleAnnotationsForCategory(annotationCategory) {
    const currentlyDisabled = this.getCategoryDisabled(annotationCategory);
    const annotationTypes = Array.from(this.props.annotationTypes)
      .filter((annotationType) => (
        annotationType.checkType === annotationCategory &&
          this.stateForAnnotationType(annotationType.id).disabled === currentlyDisabled
      ));
    annotationTypes.forEach((annotationType) => {
      this.props.onAnnotationTypeClick(annotationType.id);
    });
  }

  getCategoryName(categoryId) {
    const annotationCategory = this.props.annotationCategories.find((annotationCategory) => annotationCategory.id === categoryId);
    return annotationCategory ? annotationCategory.display : categoryId;
  }

  getCategoryDisabled(categoryId) {
    return this.props.annotationTypes.
      filter((annotationType) => annotationType.checkType === categoryId).
      map((annotationType) => this.stateForAnnotationType(annotationType.id)).
      every((annotationType) => annotationType.disabled);
  }

  render() {
    const annotationTypes = sortBy(
      Array.from(this.props.annotationTypes),
      (annotationType) => annotationType.display.displayOrder);

    const groupedAnnotationTypes = groupBy(annotationTypes, (annotationType) => annotationType.checkType);

    return (
      <div id="kt-sidepanel-kt">
        <div className="categories-collection">
          {Object.keys(groupedAnnotationTypes).map((annotationCategory) =>
            <div className="category-header-margin" key={annotationCategory}>
              <CategoryHeaderArrowComponent
                closed={!this.state.areAnnotationsOpenForCategory[annotationCategory]}
                onClick={this.toggleAnnotationCategory.bind(this, annotationCategory)} />
              { " " }
              <CategoryHeaderButtonComponent
                annotationCategory={annotationCategory}
                annotationCategoryName={this.getCategoryName(annotationCategory)}
                disabled={this.getCategoryDisabled(annotationCategory)}
                onClick={this.toggleAnnotationsForCategory.bind(this, annotationCategory)}
              />
              {this.state.areAnnotationsOpenForCategory[annotationCategory] && groupedAnnotationTypes[annotationCategory].map((annotationType) => {
                const annotationTypeState = this.stateForAnnotationType(annotationType.id);
                return (
                  <AnnotationTypeButtonComponent
                    annotationType={{
                      id: annotationType.id,
                        name: annotationType.display.name,
                        count: annotationTypeState.count || 0,
                        disabled: annotationTypeState.disabled || false,
                        color: annotationType.display.color,
                        markType: annotationType.display.markType
                    }}
                    key={annotationType.id}
                    onAnnotationTypeClick={this.props.onAnnotationTypeClick}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

AnnotationTypesGroupedPanelComponent.propTypes = {
  annotationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      checkType: PropTypes.string.isRequired,
      display: PropTypes.shape({
        color: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        markType: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  annotationCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired
    })
  ).isRequired,
  annotationTypesState: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onAnnotationTypeClick: PropTypes.func.isRequired
};

const CategoryHeaderButtonComponent = ({ annotationCategory, annotationCategoryName, disabled, onClick }) => {
    return (
      <button className={`category-header -${disabled ? "disabled" : annotationCategory }`}
        onClick={onClick}>
        <span className="category-text">
          {annotationCategoryName}
        </span>
      </button>
    );
};

CategoryHeaderButtonComponent.propTypes = {
  disabled: PropTypes.bool,
  annotationCategory: PropTypes.string.isRequired,
  annotationCategoryName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const CategoryHeaderArrowComponent = ({ closed, onClick }) => {
  return (
    <button className={`category-header-arrow ${closed ? "-right" : "-down"}`}
      onClick={onClick}>
    </button>
  );
};

CategoryHeaderArrowComponent.propTypes = {
  closed: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default AnnotationTypesGroupedPanelComponent;
