const KTConfig = {
  contextMenu: {
    showDescription: true,
    ignoreButtons: false,
    addToPersonalList: false
  },

  sidepanel: {
    removeAnnotations: false,
    languageLevelControl: true,
    lastCheck: false,
    languageLevelView: true,
    deepChecks: {
      correctness: true,
      readability: false
    },
    language: {
      show: false,
      services: {}
    },
    addSuggestions: true,
    annotationInfo: false
  },

  translations: {
    removeAnnotations: "Wis markeringen",
    correctness: "Correctheid",
    readability: "Leesbaarheid",
    evaluateOn: "Geef schrijftips voor",
    wantedLanguageLevel: "Gewenst taalniveau",
    measuredLanguageLevel: "Gemeten taalniveau",
    lastEvaluation: "Laatste evaluatie",
    notCheckedYet: "Nog niet gecontroleerd",
    addToReadabilityList: "Toevoegen aan persoonlijke lijst",
    addSuggestion: "Suggesties voor dure woorden geven",
    addSuggestionExplanation: "Kent u een duur woord of dure woordgroep die Klinkende Taal nog niet markeert? Stuur uw suggestie op naar de redactie. U kunt ook eenvoudigere alternatieven voor het woord meesturen.",
    addSuggestionPlaceholder: "Vul hier uw dure woord of woordgroep in",
    addAlternativePlaceholder: "Vul hier een eenvoudiger alternatief in",
    suggestionAdded: "Bedankt voor uw suggestie. Zodra de redactie uw woord goedkeurt, zal Klinkende Taal het in uw teksten en die van uw collega's markeren.",
    suggestionNotAdded: "Uw suggestie kon niet toegevoegd worden. Probeer het later nog een keer.",
    eLearning: "E-learning"
  },

  extraStylesheets: [],
  markCategories: [],
  user: { customer: "kt_demo", password: "gridline" },
  documentName: "test.docx",
  webserviceURL: "/kt/analyse",
  addSuggestionURL: "/kt/suggestion",
  update(extraConfig) {
    for (const key in extraConfig) {
      this[key] = extraConfig[key];
    }
  }
};

export default KTConfig;
