import React from "react";
import PropTypes from "prop-types";

const FromLadButton = ({ disabled, onLoadDocumentFromLad }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onLoadDocumentFromLad();
  };

  return (
    <div className="from-lad">
      <input
        className={`button-element ${disabled ? "-disabled" : ""}`}
        type="submit"
        onClick={handleClick}
        disabled={disabled}
        value="Document van LAD" />
    </div>
  );
};

FromLadButton.propTypes = {
  disabled: PropTypes.bool,
  onLoadDocumentFromLad: PropTypes.func.isRequired
};

export default FromLadButton;
