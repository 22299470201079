function getOffset(element) {
  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset
  };
}

class AnnotationElementWrapper {
  constructor(annotationElement, annotations) {
    this.annotationId = annotationElement.dataset.ktid;

    this.foundAnnotation = annotations.find((annotation) => annotation.id === this.annotationId);
    this.computedDimensions = AnnotationElementWrapper.computeDimensions(annotationElement);
  }

  annotation() {
    return this.foundAnnotation;
  }

  dimensions() {
    return this.computedDimensions;
  }

  static computeDimensions(annotationElement) {
    const offset = AnnotationElementWrapper.globalOffsetOfElement(annotationElement);
    return {
      top: offset.top,
      left: offset.left,
      height: annotationElement.offsetHeight,
      width: annotationElement.offsetWidth
    };
  }

  // Returns the position of an element in top-level document coordinates
  static globalOffsetOfElement(element) {
    let x = 0;
    let y = 0;
    let localWindow = element.ownerDocument.defaultView;

    let doLoop = true;
    while (doLoop) {
      const offset = getOffset(element);
      x += offset.left;
      y += offset.top;

      // Stop if the current element is in the main window
      if (localWindow === window) { doLoop = false; }

      if (doLoop) {
        // Correct for the scrolling of the local window
        x -= localWindow.pageXOffset;
        y -= localWindow.pageYOffset;

        // Jump one iframe up, using the iframe as the element
        // for the next iteration
        element = localWindow.frameElement;
        localWindow = localWindow.parent;
      }
      // Stop if we somehow couldn't find a valid element
      if (!element) { doLoop = false; }
    }

    return { left: x, top: y };
  }
}

export default AnnotationElementWrapper;
