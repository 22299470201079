const Events = {
  on(eventType, listener) {
    this.listeners = this.listeners || {};

    const eventListeners = this.listeners[eventType] || new Set();
    eventListeners.add(listener);
    this.listeners[eventType] = eventListeners;

    return listener;
  },

  off(eventType, listener = null) {
    this.listeners = this.listeners || {};

    if(listener == null) {
      delete this.listeners[eventType];
    } else {
      const eventListeners = this.listeners[eventType] || new Set();
      eventListeners.delete(listener);
    }
  },

  once(eventType, listener) {
    const listenerFunction = (...args) => {
      listener(...args);
      this.off(eventType, listenerFunction);
    };
    this.on(eventType, listenerFunction);
  },

  trigger(eventType, ...args) {
    this.listeners = this.listeners || {};

    const eventListeners = this.listeners[eventType] || new Set();
    eventListeners.forEach((listener) => listener(...args));
  }
};

export default Events;
