import uniqueId from "lodash/uniqueId";

import PropTypes from "prop-types";

import React from "react";

const CheckTypeButton = (props) => {
  const {
    name,
    id,
    checkType,
    label,
    disabled,
    defaultChecked
  } = props;

  const handleChange = (event) => (
    props.onChange(event.target.value)
  );

  return (
    <div className="deep-check-button">
      <input
        name={name}
        id={id}
        value={checkType}
        type={"radio"}
        disabled={disabled}
        onClick={handleChange}
        defaultChecked={defaultChecked} />
      <label className={`label-element ${disabled ? "-disabled" : ""}`}
        htmlFor={id} >
        {label}
      </label>
    </div>
  );
};

CheckTypeButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  checkType: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
};

class CheckTypeSelectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: uniqueId("CheckTypeSelectionComponent") };
  }

  render() {
    const {
      disabled,
      checkTypes,
      translations,
      onCheckTypeChanged
    } = this.props;

    const {
      name
    } = this.state;

    const buttons = [];
    if (checkTypes.readability) {
      const id =  `${name}_readability`;
      buttons.push(
        <CheckTypeButton
          defaultChecked={true}
          key={"readability"}
          name={name}
          id={id}
          checkType={"readability"}
          label={translations.readability || "Leesbaarheid"}
          disabled={disabled}
          onChange={onCheckTypeChanged} />
      );
    }
    if (checkTypes.correctness) {
      const id = `${name}_correctness`;
      buttons.push(
        <CheckTypeButton
          defaultChecked={!checkTypes.readability}
          key={"correctness"}
          name={name}
          id={id}
          checkType={"correctness"}
          label={translations.correctness || "Correctheid"}
          disabled={disabled}
          onChange={onCheckTypeChanged} />
      );
    }
    if (checkTypes.correctnessFrisian) {
      const id = `${name}_correctnessFrisian`;
      buttons.push(
        <CheckTypeButton
          key={"correctnessFrisian"}
          name={name}
          id={id}
          checkType={"correctness-fries"}
          label={translations.correctnessFrisian || "Correctheid (Fries)"}
          disabled={disabled}
          onChange={onCheckTypeChanged} />
      );
    }

    if(buttons.length === 0) {
      return null;
    }

    return(
      <div className="check-type-choices">
        <span className="span-element title">
          {translations.evaluateOn || "Geef schrijftips voor"}
        </span>
        <div className="check-types">
          {buttons}
        </div>
      </div>
    );
  }
}

CheckTypeSelectionComponent.propTypes = {
  disabled: PropTypes.bool,
  checkTypes: PropTypes.shape({
    correctness: PropTypes.any,
    readability: PropTypes.any,
    correctnessFrisian: PropTypes.any
  }).isRequired,
  onCheckTypeChanged: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    correctness: PropTypes.string.isRequired,
    readability: PropTypes.string.isRequired,
    correctnessFrisian: PropTypes.string,
    evaluateOn: PropTypes.string
  }).isRequired
};

export default CheckTypeSelectionComponent;
export { CheckTypeButton };
