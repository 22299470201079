import PropTypes from "prop-types";
import React from "react";

import LanguageLevelUtil from "kt_jsgem/lib/language_level_util";

class LanguageLevelComponent extends React.Component {
  languageLevelToString() {
    if((this.props.languageLevel !== null) && (this.props.checkType !== "correctness")) {
      return LanguageLevelUtil.toString(this.props.languageLevel);
    } else {
      return "--";
    }
  }

  render() {
    return (
      <div className="klinkendetaal-niveau" title="Laatst berekende taalniveau">
        <span className="measured-level">
          {this.props.translations.measuredLanguageLevel || "Gemeten taalniveau"}
          <span className="level">
            {this.languageLevelToString()}
          </span>
        </span>
      </div>
    );
  }
}

LanguageLevelComponent.propTypes = {
  checkType: PropTypes.string,
  languageLevel: PropTypes.number,
  translations: PropTypes.shape({
    measuredLanguageLevel: PropTypes.string.isRequired
  }).isRequired
};

export default LanguageLevelComponent;
