import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

import { addToListComponentForCheckType } from "kt_jsgem/kt_editor/menu/add_alternative_to_list";

class MenuItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.props.readOnly) {
      this.props.onReplaceAnnotation(this.props.id, this.props.alternative);
    }
  }

  render() {
    return (
      <span
        className={this.props.readOnly ? "suggestion readonly" : "suggestion"}
        onClickCapture={this.handleClick}>
        {this.props.title}
      </span>
    );
  }
}

MenuItemComponent.propTypes = {
  readOnly: PropTypes.bool,
  onReplaceAnnotation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired
};

class MenuComponent extends React.Component {
  render() {
    const { visible, annotation } = this.props;

    if (!visible || !annotation) {
      return (
        <div className="kt-menu" />
      );
    }

    const {
      annotationType: {
        checkType,
        display: {
          name: annotationTypeName,
          description: annotationTypeDescription
        },
        removableWordCategory
      },
      annotation: {
        alternatives,
        id,
        text,
        typeId,
        reason
      },
      config,
      onAddToPersonalList,
      onIgnoreAnnotation,
      onReplaceAnnotation,
      onDisplayMessage,
      position,
      translations
    } = this.props;

    const alternativeComponents = alternatives.map((alternative) =>
      <MenuItemComponent
        key={alternative.alternative}
        readOnly={alternative.readOnly}
        id={id}
        alternative={alternative.alternative}
        title={alternative.alternative}
        onReplaceAnnotation={onReplaceAnnotation}
      />
    );

    const AddToListMenuItemComponent = addToListComponentForCheckType(checkType);

    return (
      <div className="kt-menu -visible" style={position}>
        <h2 className="title">{annotationTypeName}</h2>
        <p className="description">{annotationTypeDescription}</p>
        {alternativeComponents}
        {config.ignoreButtons &&
        <MenuItemComponent
          readOnly={false}
          id={id}
          alternative={text}
          title="Negeer instantie"
          onReplaceAnnotation={onIgnoreAnnotation}
        />
        }
        {config.addToPersonalList && removableWordCategory &&
        <AddToListMenuItemComponent
          id={id}
          text={text}
          reason={reason}
          typeId={typeId}
          translations={translations}
          onAddToList={onAddToPersonalList}
          onDisplayMessage={onDisplayMessage}
        />
        }
      </div>
    );
  }
}

MenuComponent.propTypes = {
  annotation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
    alternatives: PropTypes.arrayOf(
      PropTypes.shape({
        alternative: PropTypes.string.isRequired,
        readOnly: PropTypes.bool.isRequired
      })
    ).isRequired,
    reason: PropTypes.string
  }),
  annotationType: PropTypes.shape({
    checkType: PropTypes.string.isRequired,
    removableWordCategory: PropTypes.bool,
    display: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }).isRequired
  }),
  config: PropTypes.shape({
    ignoreButtons: PropTypes.bool,
    addToPersonalList: PropTypes.bool
  }).isRequired,
  onAddToPersonalList: PropTypes.func.isRequired,
  onIgnoreAnnotation: PropTypes.func.isRequired,
  onReplaceAnnotation: PropTypes.func.isRequired,
  onDisplayMessage: PropTypes.func.isRequired,
  position: PropTypes.object,
  translations: PropTypes.object.isRequired,
  visible: PropTypes.bool
};

class Menu {
  constructor(options) {
    if (options == null) { options = {}; }
    this.el = document.createElement("div");
    this.config = options.config;
    this.annotationTypes = options.annotationTypes;
    this.callbacks = options.callbacks;
    this.annotation = null;
    this.annotations = [];
    this.position = {
      top: 0,
      left: 0
    };
    this.visible = false;
    this.openMenu = this.openMenu.bind(this);
    this.close = this.close.bind(this);
  }

  render(){
    const props = {
      position: this.position,
      annotation: this.annotation,
      annotationType: this.annotationType,
      onReplaceAnnotation: this.callbacks.onReplaceAnnotation,
      onIgnoreAnnotation: this.callbacks.onIgnoreAnnotation,
      onAddToPersonalList: this.callbacks.onAddToPersonalList,
      onDisplayMessage: this.callbacks.onDisplayMessage,
      config: this.config.contextMenu,
      translations: this.config.translations,
      visible: this.visible
    };

    ReactDOM.render(React.createElement(MenuComponent,
      props), this.el);
  }

  update(annotation, dimensions, annotationType) {
    this.annotation = annotation;
    this.annotationType = annotationType;
    this.visible = !!(this.annotation);
    if (this.visible) {
      this.findOptimalPosition(dimensions);
    }
    this.render();
  }

  findOptimalPosition(dimensions) {
    // Show the popup under the annotation tag
    this.position = { top: dimensions.top + dimensions.height, left: dimensions.left };
    this.render();

    // Check if that still fits
    const menu = this.el.getElementsByClassName("kt-menu")[0];
    if ((dimensions.top + dimensions.height + menu.offsetHeight) > window.document.documentElement.clientHeight) {
      // If not, move to above the annotation tag
      this.position = {
        top: dimensions.top - menu.offsetHeight,
        left: dimensions.left
      };
    }
    return this.position;
  }

  openMenu(event, annotationElement) {
    if (this.visible) {
      return;
    }

    const isAnnotationVisible = (annotation) => {
      const annotationType = this.annotationTypes.find((at) => at.id === annotation.typeId);

      return !(annotationType && annotationType.disabled);
    };

    if ((annotationElement.annotation() == null) || !isAnnotationVisible(annotationElement.annotation())) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const annotationType = this.config.annotationTypes.find((at) => at.id === annotationElement.annotation().typeId);
    this.open(annotationElement.annotation(), annotationElement.dimensions(), annotationType);
  }

  open(annotation, dimensions, annotationType) {
    this.callbacks.onFocusAnnotation(annotation);
    this.update(annotation, dimensions, annotationType);
  }

  close() {
    this.callbacks.onBlurAnnotation();
    this.update(null, null, null);
  }
}

export default Menu;
