import PropTypes from "prop-types";
import React from "react";

const AdditionalActions = (props) => {
  const {
    translations,
    children
  } = props;

  return (
    <div className="additional-actions">
      <span className="span-element title">
        {translations.additionalActions || "Overige acties"}
      </span>
      <div className="actions">
        {children}
      </div>
    </div>
  );
};

AdditionalActions.propTypes = {
  translations: PropTypes.shape({
    additionalActions: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
};

export default AdditionalActions;
