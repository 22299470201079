import React, { useState } from "react";
import PropTypes from "prop-types";

import uniqueId from "lodash/uniqueId";

import { CheckTypeButton } from "kt_jsgem/kt_editor/side_panel/check_type_selection";

const AnnotationCategorySelection = ({ disabled, onCheckTypeChanged, annotationCategories, translations }) => {
  const [name] = useState(() => uniqueId("AnnotationCategorySelection"));

  if(annotationCategories.length === 0) {
    return null;
  }

  return (
    <div className="check-type-choices">
      <span className="span-element title">
        {translations.evaluateOn || "Geef schrijftips voor"}
      </span>
      <div className="check-types">
        {annotationCategories.map((annotationCategory, i) => (
          <CheckTypeButton
            defaultChecked={i === 0}
            key={annotationCategory.id}
            name={name}
            id={`${name}_${annotationCategory.id}`}
            checkType={annotationCategory.id}
            label={annotationCategory.display || annotationCategory.id}
            disabled={disabled}
            onChange={onCheckTypeChanged}
          />
        ))}
      </div>
    </div>
  );
};

AnnotationCategorySelection.propTypes = {
  disabled: PropTypes.bool,
  onCheckTypeChanged: PropTypes.func.isRequired,
  annotationCategories: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  translations: PropTypes.object
};

AnnotationCategorySelection.defaultProps = {
  translations: {}
};

export default AnnotationCategorySelection;
