import uniqueId from "lodash/uniqueId";

import PropTypes from "prop-types";

import React from "react";

import LanguageLevel from "kt_jsgem/lib/language_level";

class LanguageLevelRadioComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: uniqueId("LanguageLevelRadioComponent") };
    this.handleChange = this.handleChange.bind(this);
  }

  id(key) {
    return `${this.state.name}_${key}`;
  }

  handleChange(event) {
    this.props.onLanguageLevelChanged(parseInt(event.target.value));
  }

  render() {
    const {
      name
    } = this.state;

    const {
      languageLevel,
      translations
    } = this.props;

    const radioButtons = [];
    for (const languagelevel in LanguageLevel) {
      const id = this.id(LanguageLevel[languagelevel]);
      const checked = LanguageLevel[languagelevel] === languageLevel;

      radioButtons.push(
        <div key={id} className={`language-level-button ${ checked ? "-checked" : ""}`}>
          <input
            name={name}
            type="radio"
            value={LanguageLevel[languagelevel]}
            checked={LanguageLevel[languagelevel] === languageLevel}
            id={id}
            onChange={this.handleChange} />
          <label className="label-element" htmlFor={id}>
            {languagelevel}
          </label>
        </div>
      );
    }

    return (
      <div className="language-level-select">
        <span className="title">
          {translations.wantedLanguageLevel || "Gewenst taalniveau"}
        </span>
        <div className="language-level-control">
          {radioButtons}
        </div>
        <span />
      </div>
    );
  }
}

LanguageLevelRadioComponent.propTypes = {
  languageLevel: PropTypes.number.isRequired,
  onLanguageLevelChanged: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    wantedLanguageLevel: PropTypes.string.isRequired
  }).isRequired
};

export default LanguageLevelRadioComponent;
