import Menu from "kt_jsgem/kt_editor/menu/menu";

class MenuManager {
  constructor(parent) {
    this.closeMenu = this.closeMenu.bind(this);
    this.handleToggleMenuClickEvent = this.openMenu.bind(this);
    this.menu = new Menu;
    parent.appendChild(this.menu.el);
  }

  update({ textElement, responseAnnotationTypes, config, callbacks, annotations }) {
    this.updateTextElement(textElement);
    this.updateConfig(config);
    this.updateCallbacks(callbacks);
    this.updateResponseAnnotationTypes(responseAnnotationTypes);
    this.updateAnnotations(annotations);
  }

  updateTextElement(_textElement) {
    // Noop
  }

  updateConfig(config) {
    this.menu.config = config;
  }

  updateCallbacks(callbacks) {
    this.menu.callbacks = callbacks;
  }

  updateResponseAnnotationTypes(responseAnnotationTypes) {
    this.menu.annotationTypes = responseAnnotationTypes;
  }

  updateAnnotations(annotations) {
    this.menu.annotations = annotations;
  }

  openMenu(event, annotationElementWrapper) {
    this.menu.openMenu(event, annotationElementWrapper);
  }

  closeMenu() {
    this.menu.close();
  }
}

export default MenuManager;
