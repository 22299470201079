/* eslint-disable camelcase */
const LegacyLoaderColors = {
  te_lange_alinea:        ["underline", "#b8b8b8"],
  ontbrekende_kopjes:     ["underline", "#c7d3cb"],
  duur_toelaatbaar:       ["highlight", "#e4796a"],
  duur_verboden:          ["highlight", "#d83f29"],
  vaktermen_toelaatbaar:  ["highlight", "#27df4c"],
  vaktermen_verboden:     ["highlight", "#18a134"],
  mogelijke_vaktermen:    ["highlight", "#8faeeb"],
  lange_woorden:          ["highlight", "#4e7fe0"],
  hulpwerkwoorden:        ["highlight", "#e4e9e6"],
  voornaamwoorden:        ["highlight", "#edc980"],
  naamwoordstijl:         ["highlight", "#e3ac3d"],
  dubbele_ontkenning:     ["highlight", "#787878"],
  spelling:               ["underline", "#d83f29"],
  spelling_dt:            ["underline", "#e3ac3d"],
  organisatietaal:        ["highlight", "#e4e9e6"],
  getallen_leestekens:    ["highlight", "#e3ac3d"],
  determiner:             ["underline", "#4e7fe0"],
  te_lange_zinnen:        ["underline", "#e4796a"],
  ingewikkelde_zinnen:    ["underline", "#d83f29"],
  passieve_zinnen:        ["underline", "#27df4c"],
  opsommingen:            ["underline", "#edc980"],
  tangconstructies:       ["underline", "#e3ac3d"],
  long_paragraphs:        ["underline", "#b8b8b8"],
  pompous_words:          ["highlight", "#d83f29"],
  long_sentences:         ["underline", "#e4796a"],
  complex_sentences:      ["underline", "#d83f29"],
  passive_sentences:      ["underline", "#27df4c"],
  paragrawe_te_lank:      ["highlight", "#e8e8e8"],
  skaarse_woorde:         ["highlight", "#e4796a"],
  valse_vriende:          ["highlight", "#d83f29"],
  onsuiwer_afrikaans:     ["highlight", "#e3ac3d"],
  sinne_te_lank:          ["underline", "#4e7fe0"],
  ingewikkelde_sinne:     ["underline", "#d83f29"],
  sinne_in_passiewe_vorm: ["underline", "#27df4c"],
  stavering_wurdlist:     ["highlight", "#d83f29"],
  dialekt:                ["highlight", "#8faeeb"],
  dialect:                ["highlight", "#8faeeb"],
  hollanisme:             ["highlight", "#fdb957"]
};

export default LegacyLoaderColors;
