import React from "react";
import PropTypes from "prop-types";

class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if(this.props.onClick && !this.props.readOnly) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <div className={this.props.readOnly ? `${this.props.className} readonly` : this.props.className}>
        <input
          type="button"
          value={this.props.title}
          onClick={this.handleClick}
          className="button-element" />
      </div>
    );
  }
}

ButtonComponent.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  title: PropTypes.string.isRequired
};

ButtonComponent.defaultProps = {
  className: ""
};

export default ButtonComponent;
