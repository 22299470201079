import PropTypes from "prop-types";
import React from "react";

class RemoveAnnotationsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.clearButtonClicked();
  }

  render() {
    const {
      translations
    } = this.props;

    return (
      <div className="remove-annotations">
        <span className="span-element clear-annotations-button">
          <button className="button-element clear-annotations -white" onClick={this.handleClick}>
            {translations.removeAnnotations || "Wis markeringen"}
          </button>
        </span>
      </div>
    );
  }
}

RemoveAnnotationsComponent.propTypes = {
  translations: PropTypes.shape({
    removeAnnotations: PropTypes.string.isRequired
  }).isRequired,
  clearButtonClicked: PropTypes.func.isRequired
};

export default RemoveAnnotationsComponent;
