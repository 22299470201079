import $ from "jquery";

const JQueryTransport = {
  shouldUse() {
    return true;
  },

  performRequest(url, xmlString, options) {
    const ajaxOptions = {
      url,
      type: options.method || "POST",
      data: xmlString,
      dataType: "xml",
      contentType: "application/xml"
    };

    if(options.user) {
      const {
        user
      } = options;

      ajaxOptions.headers = {
        "Authorization": `Basic ${btoa(`${user.username}:${user.password}`)}`
      };

      if(user.sessionId) {
        ajaxOptions.headers["X-Kt-Sessionid"] = user.sessionId;
      }

      ajaxOptions.crossDomain = false;
    }

    return new Promise((resolve, reject) => {
      $.ajax(Object.assign(ajaxOptions, {
        success(data) {
          resolve(data);
        },
        error(jqxhr) {
          reject(jqxhr.statusText);
        }
      }));
    });
  }
};

export default JQueryTransport;
