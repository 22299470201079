import Fragment from "kt_jsgem/lib/fragment";
import ParagraphBoundry from "kt_jsgem/lib/paragraph_boundry";

class HtmlText {
  constructor(html) {
    if ((typeof html === "string") || html instanceof String) {
      throw new Error("HtmlText: Received string as HTML, please provide an Element instance");
    }

    this.node = html;
  }

  textNodes() {
    return this.findTextNodes(this.node);
  }

  findTextNodes(node) {
    let textNodes = [];
    for (const child of Array.from(node.childNodes)) {
      const isParagraphBoundry = HtmlText.isParagraphBoundry(child);
      if (isParagraphBoundry || HtmlText.isBreak(child)) {
        textNodes.push(new ParagraphBoundry);
      }

      if (child.nodeType === Node.TEXT_NODE) {
        textNodes.push(new Fragment(child));
      } else {
        textNodes = textNodes.concat(this.findTextNodes(child));
        if (isParagraphBoundry) {
          textNodes.push(new ParagraphBoundry);
        }
      }
    }

    return textNodes;
  }
}

HtmlText.isBreak = (node) => node.nodeName === "BR";

HtmlText.isParagraphBoundry = (node) => (new RegExp(`^(p|h[1-6]|div|li)$`, "i")).test(node.nodeName);

// Utility function to use HtmlText with a string of HTML
HtmlText.elementForHtml = function(html) {
  let node;
  if ((typeof html === "string") || html instanceof String) {
    node = document.createElement("div");
    node.innerHTML = html;
  } else {
    node = html;
  }
  return node;
};

export default HtmlText;
