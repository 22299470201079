import React from "react";
import PropTypes from "prop-types";

const addedToListTranslation = function(msg, word) {
  const parts = msg.split(":word");
  return parts.join(word);
};

const addToWordListMenuItemComponentFactory = (ButtonComponent = "span") => {
  class AddToWordListMenuItemComponent extends React.Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
      const { translations } = this.props;

      this.props.onAddToList(this.props.id, this.props.text, this.props.reason, this.props.mainCategory, this.props.typeId).then((success) => {
        if(translations.addedToWordList && success) {
          this.props.onDisplayMessage("notification", addedToListTranslation(translations.addedToWordList, this.props.reason));
        } else if(translations.failedToAddToWordList && !success) {
          this.props.onDisplayMessage("info", addedToListTranslation(translations.failedToAddToWordList, this.props.reason));
        }
      });
    }

    render() {
      const { translations } = this.props;

      return (
        <ButtonComponent className="add-suggestion" onClick={this.handleClick}>
          {translations.addToWordList}
        </ButtonComponent>
      );
    }
  }

  AddToWordListMenuItemComponent.propTypes = {
    translations: PropTypes.shape({
      addToWordList: PropTypes.string.isRequired,
      addedToWordList: PropTypes.string.isRequired,
      failedToAddToWordList: PropTypes.string.isRequired
    }).isRequired,
    onAddToList: PropTypes.func.isRequired,
    onDisplayMessage: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
    mainCategory: PropTypes.string.isRequired
  };

  AddToWordListMenuItemComponent.defaultProps = {
    reason: ""
  };

  const AddToReadabilityListMenuItemComponent = (props) => {
    const {
      translations
    } = props;

    const readabilityTranslations = {
      addToWordList: translations.addToReadabilityList,
      addedToWordList: translations.addedToReadabilityList,
      failedToAddToWordList: translations.failedToAddToReadabilityList
    };

    return (
      <AddToWordListMenuItemComponent
        {...props}
        translations={readabilityTranslations}
        mainCategory="readability"
      />
    );
  };

  AddToReadabilityListMenuItemComponent.propTypes = {
    translations: PropTypes.shape({
      addToReadabilityList: PropTypes.string.isRequired,
      addedToReadabilityList: PropTypes.string.isRequired,
      failedToAddToReadabilityList: PropTypes.string.isRequired
    }).isRequired
  };

  const AddToCorrectnessListMenuItemComponent = (props) => {
    const {
      translations
    } = props;

    const correctnessTranslations = {
      addToWordList: translations.addToCorrectnessList,
      addedToWordList: translations.addedToCorrectnessList,
      failedToAddToWordList: translations.failedToAddToCorrectnessList
    };

    return (
      <AddToWordListMenuItemComponent
        {...props}
        translations={correctnessTranslations}
        mainCategory="correctness"
      />
    );
  };

  AddToCorrectnessListMenuItemComponent.propTypes = {
    translations: PropTypes.shape({
      addToCorrectnessList: PropTypes.string.isRequired,
      addedToCorrectnessList: PropTypes.string.isRequired,
      failedToAddToCorrectnessList: PropTypes.string.isRequired
    }).isRequired
  };

  const addToListComponentForCheckType = (checkType) => (
    checkType === "readability" ?
      AddToReadabilityListMenuItemComponent
    :
      AddToCorrectnessListMenuItemComponent
  );
  return addToListComponentForCheckType;
};

const addToListComponentForCheckType = addToWordListMenuItemComponentFactory();

export { addToListComponentForCheckType, addToWordListMenuItemComponentFactory };
