import React from "react";

const CheckingSpinner = () => (
  <div className="checking-spinner">
    <div className="bounce1"/>
    <div className="bounce2"/>
    <div className="bounce3"/>
  </div>
);

export default CheckingSpinner;
