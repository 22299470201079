import LanguageLevel from "kt_jsgem/lib/language_level";

const LanguageLevelUtil = {
  toString(languageLevel) {
    const level = (languageLevel != null) ? parseFloat(languageLevel) : undefined;
    if ((level >= LanguageLevel.A1) && (level < LanguageLevel.A2)) {
      return "A1";
    } else if ((level >= LanguageLevel.A2) && (level < LanguageLevel.B1)) {
      return "A2";
    } else if ((level >= LanguageLevel.B1) && (level < LanguageLevel.B2)) {
      return "B1";
    } else if ((level >= LanguageLevel.B2) && (level < LanguageLevel.C1)) {
      return "B2";
    } else if ((level >= LanguageLevel.C1) && (level < LanguageLevel.C2)) {
      return "C1";
    } else if ((level >= LanguageLevel.C2) && (level < (LanguageLevel.C2 + 1))) {
      return "C2";
    } else {
      return null;
    }
  },

  fromUnsafeString(languageLevelString) {
    if ((languageLevelString === "A1") ||
        (languageLevelString === "A2") ||
        (languageLevelString === "B1") ||
        (languageLevelString === "B2") ||
        (languageLevelString === "C1") ||
        (languageLevelString === "C2")) {
      return languageLevelString;
    } else {
      return null;
    }
  }
};

export default LanguageLevelUtil;
